<template>
  <div>
    <base-header class="pb-6">
      <b-row class="py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">
            Detail Laporan Kondisi Tidak Normal
          </h6>
        </b-col>
        <b-col lg="6" cols="7" style="display: flex; justify-content: flex-end">
          <base-button @click="back()">Kembali</base-button>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <!-- akun -->
        <b-col xl="12">
          <b-card>
            <template v-slot:header>
              <!-- Subtitle -->
              <h6 class="surtitle">RINCIAN</h6>
              <!-- Title -->
              <div class="card-body" v-if="isLoading">
                No Data
              </div>
              <!-- content -->
              <div class="card-body" v-if="!isLoading">
                <div class="row mb-4">
                  <div class="col-lg-4">
                    <strong>Nama Perusahaan</strong>
                  </div>
                  <div class="col-lg-8">
                    {{ listData.comp_name }}
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-4">
                    <strong>Jenis Industri</strong>
                  </div>
                  <div class="col-lg-8">
                    <span>
                      {{ listData.comp_type_name }}
                    </span>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-4">
                    <strong>Provinsi</strong>
                  </div>
                  <div class="col-lg-8">
                    {{ listData.province_name }}
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-4">
                    <strong>Kabupaten / Kota</strong>
                  </div>
                  <div class="col-lg-8">
                    {{ listData.kabkot_name }}
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-4">
                    <strong>Kondisi</strong>
                  </div>
                  <div class="col-lg-8">
                    <span>{{ listData.kondisi }}</span>
                  </div>
                </div>

                <div class="row mb-4" v-if="listData.kondisi == 'Kondisi Tidak Normal'">
                  <div class="col-lg-4">
                    <strong>Kondisi Tidak Normal</strong>
                  </div>
                  <div class="col-lg-8">
                    <span>{{listData.kondisi_detail.status}}</span>
                  </div>
                </div>

                <div class="row mb-4" v-if="listData.kondisi == 'Kondisi Darurat'">
                  <div class="col-lg-4">
                    <strong>Kondisi Darurat</strong>
                  </div>
                  <div class="col-lg-8">
                    <span>{{listData.kondisi_darurat }}</span>
                  </div>
                </div>

                <!-- <div class="row mb-4" v-if="listData.kondisi_tidak_normal == 'Kondisi lain yang menyebabkan SPARING tidak dapat digunakan secara optimal'">
                  <div class="col-lg-4">
                    <strong>Kondisi Lain yang Menyebabkan SPARING Tidak dapat Digunakan Secara Optimal</strong>
                  </div>
                  <div class="col-lg-8">
                    <span>{{listData.kondisi_lainnya }}</span>
                  </div>
                </div> -->

                <div class="row mb-4">
                  <div class="col-lg-4">
                    <strong>Tanggal Kejadian</strong>
                  </div>
                  <div class="col-lg-8">
                    <span>{{ listData.kondisi_detail.start_date }}</span>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-4">
                    <strong>End Tanggal Kejadian</strong>
                  </div>
                  <div class="col-lg-8">
                    <span>{{ listData.kondisi_detail.end_date}}</span>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-4">
                    <strong>Lokasi</strong>
                  </div>
                  <div class="col-lg-8">
                    <span>{{listData.location}}</span>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-4">
                    <strong>Fasilitas / Unit</strong>
                  </div>
                  <div class="col-lg-8">
                    <span>{{listData.fasilitas}}</span>
                  </div>
                </div>

                <!-- Sparing -->
                <div v-if="listData.fasilitas == 'Alat Sparing' || listData.fasilitas == 'Alat Sparing & IPAL'">
                  <div class="row mb-4">
                    <div class="col-lg-4">
                      <strong>Merek Alat Sparing</strong>
                    </div>
                    <div class="col-lg-8">
                      <span>{{listData.fasilitas_sparing.merek}}</span>
                    </div>
                  </div>

                  <div class="row mb-4">
                    <div class="col-lg-4">
                      <strong>Tanggal Instalasi Alat Sparing</strong>
                    </div>
                    <div class="col-lg-8">
                      <span>{{ listData.fasilitas_ipal.tanggal_instalasi }}</span>
                    </div>
                  </div>

                  <div class="row mb-4">
                    <div class="col-lg-4">
                      <strong>Tanggal Dioperasikannya Alat Sparing</strong>
                    </div>
                    <div class="col-lg-8">
                      <span>{{ listData.fasilitas_ipal.tanggal_operasi }}</span>
                    </div>
                  </div>
                </div>

                <!-- IPAL -->
                <div v-if="listData.fasilitas == 'Alat IPAL' || listData.fasilitas == 'Alat Sparing & IPAL'">
                  <div class="row mb-4">
                    <div class="col-lg-4">
                      <strong>Tanggal Instalasi Alat IPAL</strong>
                    </div>
                    <div class="col-lg-8">
                      <span>{{ listData.fasilitas_ipal.tanggal_instalasi }}</span>
                    </div>
                  </div>

                  <div class="row mb-4">
                    <div class="col-lg-4">
                      <strong>Tanggal Dioperasikannya Alat IPAL</strong>
                    </div>
                    <div class="col-lg-8">
                      <span>{{  listData.fasilitas_ipal.tanggal_operasi  }}</span>
                    </div>
                  </div>

                  <div class="row mb-4">
                    <div class="col-lg-4">
                      <strong>Kapasitas Desain dan Operasional Alat IPAL</strong>
                    </div>
                    <div class="col-lg-8">
                      <span>{{ listData.tanggal_operasi_alat_ipal  }}</span>
                    </div>
                  </div>
                </div>



                <div class="row mb-4">
                  <div class="col-lg-4">
                    <strong>Apakah Penyebab Kejadian Sudah Diatasi?</strong>
                  </div>
                  <div class="col-lg-8">
                    <span>{{ listData.status_penanganan.sudah_teratasi }}</span>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-4">
                    <strong>Tanggal Kejadian Sudah Teratasi</strong>
                  </div>
                  <div class="col-lg-8">
                    <!-- <span>{{ listData.tanggal_akhir_kejadian }}</span> -->
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-4">
                    <strong >Apakah Ada Keluhan dari Masyarakat Karena Kejadian Ini?</strong>
                  </div>
                  <div class="col-lg-8">
                    <span>{{ listData.status_penanganan.keluhan_masyarakat }}</span>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-4">
                    <strong>Lampiran Prosedur Penanganan</strong>
                  </div>
                  <div class="col-lg-8">
                    <span><a :href="`https://${listData.penanganan_path_id}`" target="blank">Lihat File</a></span>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-4">
                    <strong>Surat Penyampaian Kondisi Tidak Normal Atau Kondisi Darurat</strong>
                  </div>
                  <div class="col-lg-8">
                    <span><a :href="`https://${listData.penyampaian_path_id}`" target="blank">Lihat File</a></span>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-4">
                    <strong>Lampiran Bukti Logbook Harian Yang Ditanda Tangani Minimal Setara Manager Lingkungan</strong>
                  </div>
                  <div class="col-lg-8">
                    <span><a :href="`https://${listData.logbook_path_id}`" target="blank">Lihat File</a></span>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-4">
                    <strong>Surat Pernyataan</strong>
                  </div>
                  <div class="col-lg-8">
                    <span><a :href="`https://${listData.pernyataan_path_id}`" target="blank">Lihat File</a></span>
                  </div>
                </div>

              </div>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import API from '../../../api/base_url'
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BaseHeader from "@/components/BaseHeader";
import moment from "moment"

export default {
  components: {
    BaseHeader,
    RouteBreadCrumb
  },
  mounted() {
    this.getData()
  },  
  data() {
    return {
      isLoading: false,
      listData : [],
    };
  },
  methods: {
    getData() {
      this.isLoading = true
      let headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
        
      API.get(`upnormal-reports/${this.$route.params.id}` , {headers})
        .then(({data}) => {
          console.log(data.data);
          if (data.data == null) {
            this.listData = []
            this.isLoading = false
          } else {
            this.listData = data.data
            this.isLoading = false
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false
      })
    },
    back() {
      this.$router.push("/laporan-kondisi-tidak-normal");
    },
  },
};
</script>
<style></style>
